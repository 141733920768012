import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Dialog, Classes } from '@blueprintjs/core';

import { t } from 'polotno/utils/l10n';
 
import { useProject } from './project';

 
export const ShowPropertiesDialog = observer(
  ({ isOpen, onClose, element }) => {
    const project = useProject();
    const [src, setSrc] = React.useState(element.src);
 

    React.useEffect(() => {
      setSrc(element.src);
    }, [element.src]);

    const [removing, setRemoving] = React.useState(false);

    const [progress, setProgress] = React.useState(0);

    React.useEffect(() => {
      if (!isOpen || !removing) {
        setProgress(0);
        return;
      }
      const averageTime = 30000;
      const steps = 95;
      const stepTime = averageTime / steps;
      const interval = setInterval(() => {
        setProgress((progress) => progress + 1);
      }, stepTime);
      return () => clearInterval(interval);
    }, [isOpen, removing]);

    const handleRemove = async () => {
      setRemoving(true);
    
      setRemoving(false);
    };

    const finished = src !== element.src;

    const moreButton = (
      <a
        onClick={() => {
          project.puterModalVisible = true;
        }}
        href="#"
      >
        Need more?
      </a>
    );

    return (
      <Dialog
        // icon="info-sign"
        onClose={onClose}
        title="Show Properties"
        isOpen={isOpen}
        style={{
          width: '80%',
          maxWidth: '700px',
        }}
      >
        <div className={Classes.DIALOG_BODY}>
          <img
            src={src}
            style={{ width: '100%', maxHeight: '400px', objectFit: 'contain' }}
          />
        </div>
        <div className={Classes.DIALOG_FOOTER} style={{ position: 'relative' }}>
          <div style={{ position: 'absolute', top: '5px' }}>
            Powered by 
           
          </div>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <div style={{ padding: '5px' }}>
             
                <div>
                  You have no credits. They will renew tomorrow. {moreButton}
                </div>
              
            </div>
            {!finished && (
              <Button
                onClick={handleRemove}
                loading={removing}
                 
              >
                {t('toolbar.showProperties')}
              </Button>
            )}
            {finished && (
              <>
                <Button
                  onClick={() => {
                    setSrc(element.src);
                    onClose();
                  }}
                  loading={removing}
                >
                  {t('toolbar.cancelShowProperties')}
                </Button>
                <Button
                  onClick={() => {
                    element.set({ src });
                    onClose();
                  }}
                  loading={removing}
                  intent="primary"
                >
                  {t('toolbar.confirmShowProperties')}
                </Button>
              </>
            )}
          </div>
        </div>
      </Dialog>
    );
  }
);

export const  ShowProperties = ({ element }) => {
  const [removeDialogOpen, toggleDialog] = React.useState(false);
  return (
    <>
      <Button
        text={t('toolbar.showProperties')}
        minimal
        onClick={(e) => {
          toggleDialog(true);
        }}
      />
      <ShowPropertiesDialog
        isOpen={removeDialogOpen}
        onClose={() => {
          toggleDialog(false);
        }}
        element={element}
      />
    </>
  );
};
