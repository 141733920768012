import React from 'react';
import { observer } from 'mobx-react-lite';

import { Button, Dialog, Classes } from '@blueprintjs/core';
import { useAuth0 } from '@auth0/auth0-react';
import * as api from '../api';
import { useProject } from '../project';

let AVAILABLE_SIZES = [];
  
export const OpenTemplateModal = observer(({ store, onClose, isOpen  }) => {
  const {
    loginWithPopup,
    isLoading,
    getAccessTokenSilently,
    user,
    isAuthenticated,
    logout,
  } = useAuth0();

  const project = useProject();
  const loadProjects = async () => {
    //setSubscriptionLoading(true);
    let projects = await api.listProjects({})
    //alert(JSON.stringify(projects))
    //setSubscriptionLoading(false);
    AVAILABLE_SIZES = []
    for (let i=0; i < projects.length; i++)
    {
      AVAILABLE_SIZES.push({ id: projects[i]._id, name: projects[i].name  })
    }
  };
  //alert("  loadProjects();")
    loadProjects();
  const [subscriptionLoading, setSubscriptionLoading] = React.useState(true);
  const [subscription, setSubscription] = React.useState(null);

  const loadSubscription = async () => {
    setSubscriptionLoading(true);
    const accessToken = await getAccessTokenSilently({});
    const res = await api.getUserSubscription({ accessToken });
    setSubscription(res.subscription);
    setSubscriptionLoading(false);
  };


  React.useEffect(() => {
    if (isLoading) {
      return;
    }
    if (!isAuthenticated) {
      return;
    }

    loadSubscription();
  

  }, [isLoading, isAuthenticated, getAccessTokenSilently]);

  return (
    <Dialog
      onClose={onClose}
      title="Show your support!"
      isOpen={isOpen}
      style={{
        width: '80%',
        maxWidth: '600px',
      }}
    >
      <div className={Classes.DIALOG_BODY}>
        {subscription && (
          <>
            <p>Thanks for your support! 😍 </p>
            <p>
              Your subscription is active. The next payment will be on{' '}
              {new Date(subscription.current_period_end * 1000).toDateString()}{' '}
            </p>
          </>
        )}
        {!subscription && (
          <>
            <p>
              ✋ Le template précédent sera automatiquement sauvegardé.
            </p>
            <p>
              <b>Sélectionner votre template</b>
            </p>
            <div>
        {AVAILABLE_SIZES.map(({ name, id }, i) => (
          <Button
            key={i}
            style={{ width: '100%', marginBottom: '20px' }}
            onClick={async () => {
             // store.setSize(width, height);
          
            let record = await api.loadTemplate({
              id: id,
              authToken: '',
            });
 
            project.id = record._id;
            project.name = record.name;
            project.save();
            onClose()

            }}
          >
            {name}
          </Button>
        ))}
      </div>
           
            <p>
              pour sortir sans choisir de template, veuillez cliquer sur 'Fermer'
            </p>
          </>
        )}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div
          className={Classes.DIALOG_FOOTER_ACTIONS}
          style={{ display: 'flex', justifyContent: 'center' }}
        >
         
          <Button onClick={onClose}>Fermer</Button>
        </div>
      </div>
    </Dialog>
  );
});
