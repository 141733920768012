import React from 'react';
import { observer } from 'mobx-react-lite';
//import { InputGroup, Card, Button } from '@blueprintjs/core';
import { NumericInput, Switch, Alignment } from '@blueprintjs/core';
import { useInfiniteAPI } from 'polotno/utils/use-api';
// import { urlToBase64, svgToURL } from 'polotno/utils/svg';
import { SectionTab } from 'polotno/side-panel';
import * as svg from 'polotno/utils/svg';
import { getKey } from 'polotno/utils/validate-key';
// import our own icon
import BsFillGrid3X3GapFill from '@meronex/icons/bs/BsFillGrid3X3GapFill';

// import { getImageSize } from 'polotno/utils/image';
 
// import { ImagesGrid } from 'polotno/side-panel/images-grid';
// function to remove all grid-related elements
// we also can hide them instead
// but I think removing is just quicker
function clearGrid() {
    const gridElements = window.store.activePage.children.filter(
      (child) => child.name === 'grid'
    );
    const ids = gridElements.map((el) => el.id);
    window.store.deleteElements(ids);
  }
  
  // "dot" is a fake svg elements with no content
  // but polotno will use it as snap point
  function createDot(x, y) {
    const template = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1 1"></svg>`;
    const url = svg.svgToURL(template);
    window.store.activePage.addElement({
      x,
      y,
      type: 'svg',
      width: 1,
      height: 1,
      src: url,
      name: 'grid',
      selectable: false,
    });
  }
  
  function generateGrid(rows, cols) {
    clearGrid();
    const { width, height } =  window.store;
    const dx = width / rows;
    const dy = width / cols;
    // generate svg data for grid image
    const template = `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${width} ${height}">
      ${[...Array(rows - 1)]
        .map(
          (_, index) =>
            `<line x1="${dx * (index + 1)}" y1="0" x2="${
              dx * (index + 1)
            }" y2="${height}" stroke="blue" stroke-width="2"/>`
        )
        .join('')}
        ${[...Array(cols - 1)]
          .map(
            (_, index) =>
              `<line x1="0" y1="${dy * (index + 1)}" x2="${width}" y2="${
                dy * (index + 1)
              }" stroke="blue" stroke-width="2"/>`
          )
          .join('')}
    </svg>`;
  
    // add grid image into the page
    const url = svg.svgToURL(template);
    window.store.activePage.addElement({
      type: 'svg',
      width,
      height,
      src: url,
      name: 'grid',
      selectable: false,
      opacity: 0.2,
      alwaysOnTop: true,
    });
  
    // add fake elemtns to snap on grid
    [...Array(rows - 1)].forEach((_, index) => {
      createDot(dx * (index + 1), 0);
    });
    [...Array(cols - 1)].forEach((_, index) => {
      createDot(0, dy * (index + 1));
    });
  }
 
// define the new custom section
export // define the new custom section
const GridSection = {
  name: 'grid',
  Tab: (props) => (
    <SectionTab name="Grid" {...props}>
      <BsFillGrid3X3GapFill />
    </SectionTab>
  ),
  // we need observer to update component automatically on any store changes
  Panel: observer(({ store }) => {
    const [visible, setVisible] = React.useState(true);
    const [rows, setRows] = React.useState(5);
    const [cols, setCols] = React.useState(5);
    React.useEffect(() => {
      if (!visible) {
        clearGrid();
      } else {
        generateGrid(rows, cols);
      }
    }, [rows, cols, visible]);
    return (
      <div>
        <div>
          <Switch
            checked={visible}
            onChange={(val) => {
              setVisible(val.target.checked);
            }}
            alignIndicator={Alignment.RIGHT}
            style={{
              marginTop: '8px',
              marginBottom: '25px',
            }}
          >
            Show grid
          </Switch>
          <div style={{ width: '50%', display: 'inline-block' }}>Rows:</div>
          <div style={{ width: '50%', display: 'inline-block' }}>
            <NumericInput
              fill
              value={rows}
              onValueChange={(rows) => {
                setRows(rows || 1);
              }}
              min={1}
              max={100}
              selectAllOnFocus
            />
          </div>
        </div>
        <div style={{ paddingTop: '10px' }}>
          <div style={{ width: '50%', display: 'inline-block' }}>Cols:</div>
          <div style={{ width: '50%', display: 'inline-block' }}>
            <NumericInput
              fill
              value={cols}
              onValueChange={(cols) => {
                setCols(cols || 1);
              }}
              min={1}
              max={100}
              selectAllOnFocus
            />
          </div>
        </div>
      </div>
    );
  }),
};

