import React from 'react';
import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useRef } from 'react';
import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from 'polotno';
import { Toolbar } from 'polotno/toolbar/toolbar';
import { ZoomButtons } from 'polotno/toolbar/zoom-buttons';
import { SidePanel, DEFAULT_SECTIONS } from 'polotno/side-panel';
import { Workspace } from 'polotno/canvas/workspace';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, NumericInput, Divider, MenuDivider, Navbar, MenuItem, Menu, Label } from '@blueprintjs/core';
import { ItemPredicate, ItemRenderer, Select2 } from "@blueprintjs/select";
import { InputGroup, Popover, } from '@blueprintjs/core';
import { pxToUnitRounded, unitToPx, pxToUnit } from 'polotno/utils/unit';
import { IconNames } from "@blueprintjs/icons";
import { loadFile } from './file';
import { QrSection } from './sections/qr-section';
// import { ThenounprojectSection } from './thenounproject-section';
import { QuotesSection } from './sections/quotes-section';
import { IconsSection } from './sections/icons-section';
import { ShapesSection } from './sections/shapes-section';
import { StableDiffusionSection } from './sections/stable-diffusion-section';
import { Editor } from '@tinymce/tinymce-react';

import { Tab, Tabs } from "@blueprintjs/core";

//import { MyDesignsSection } from './sections/my-designs-section';

import { GridSection } from './sections/grid-section';


import { useProject } from './project';


import { BarcodeSection, getBarcode } from './sections/barcode-section';
import { VariableSection } from './sections/variable-section';

import { ShowProperties } from './show-properties';

import { ImageRemoveBackground } from './background-remover';

import Topbar from './topbar/topbar';
import { PuterModal } from './puter-modal';
const TOP_100_FILMS = [
  { rank: 0, id: "-", title: "Pas dynamique", object: "-" },
  { rank: 9999, id: "custom", title: "Personnalisé", object: "-" },

  { rank: 1, id: "brand.name", title: "Nom de la marque", object: "brand" },
  { rank: 2, id: "brand.slogan", title: "Slogan de la marque", object: "brand" },
  { rank: 3, id: "brand.overview", title: "Présentation de la marque", object: "brand" },
  { rank: 4, id: "brand.method", title: "Engagement de la marque", object: "brand" },


  { rank: 510, id: "brand.address.street", title: "Adresse de la marque", object: "brand" },
  { rank: 511, id: "brand.email", title: "Email de la marque", object: "brand" },
  { rank: 512, id: "brand.phone", title: "Téléphone de la marque", object: "brand" },
  { rank: 513, id: "brand.web", title: "Site Internet de la marque", object: "brand" },

  { rank: 40, id: "entity.authorizedNumber", title: "N° d’agrément", object: "entity" },


  { rank: 5, id: "product.name", title: "Nom du produit", object: "product" },
  { rank: 21, id: "product.subname", title: "Sous titre du produit", object: "product" },
  { rank: 6, id: "product.reference", title: "Référence du produit", object: "product" },
  { rank: 7, id: "item.overview.origin", title: "Présentation du produit", object: "product" },
  { rank: 8, id: "item.overview.method", title: "Méthodes de fabrication", object: "product" },


  { rank: 9, id: "product.item_gtin_new", title: "Code GTIN du produit", object: "product" },
  { rank: 10, id: "product.item_weight", title: "Poids du produit", object: "product" },
  { rank: 14, id: "product.item_height", title: "Hauteur du produit", object: "product" },
  { rank: 11, id: "product.item_radius", title: "Diamètre du produit", object: "product" },
  { rank: 12, id: "product.item_length", title: "Longueur du produit", object: "product" },
  { rank: 13, id: "product.item_width", title: "Largeur du produit", object: "product" },
  { rank: 15, id: "product.base_name", title: "Nom du produit de base", object: "item" },
  { rank: 16, id: "product.internal_reference", title: "Référence interne du produit", object: "item" },
  { rank: 17, id: "item.conservation", title: "Conservation du produit", object: "item" },
  { rank: 18, id: "product.shelf_date", title: "DDM à l’expédition", object: "item" },

  { rank: 18, id: "product.item_custom_code", title: "Code Douane", object: "item" },

  { rank: 20, id: "product.technology", title: "Technologie Utilisée", object: "item" },
 

  { rank: 40, id: "product.packaging.weight", title: "Poids Brut du produit emballé", object: "product" },
  { rank: 41, id: "product.packaging.height", title: "Hauteur du produit emballé", object: "product" },
  { rank: 42, id: "product.packaging.radius", title: "Diamètre du produit emballé", object: "product" },
  { rank: 43, id: "product.packaging.length", title: "Longueur du produit emballé", object: "product" },
  { rank: 44, id: "product.packaging.width", title: "Largeur du produit emballé", object: "product" },
  { rank: 45, id: "product.packaging.dimension", title: "Dimension du produit emballé", object: "product" },
  { rank: 46, id: "product.packaging.type", title: "Type d'emballage", object: "product" },


  { rank: 50, id: "product.case_gtin_new", title: "Code GTIN du colis", object: "product" },
  { rank: 51, id: "product.case.weight", title: "Poids Brut du colis", object: "product" },
  { rank: 51, id: "product.case.weight", title: "Poids Net du colis", object: "product" },

  { rank: 53, id: "product.case.length", title: "Longueur du colis", object: "product" },
  { rank: 54, id: "product.case.width", title: "Largeur du colis", object: "product" },
  { rank: 55, id: "product.case.number", title: "Nombre unité dans le colis", object: "product" },
  { rank: 56, id: "product.case.layers", title: "Nombre de couche dans le colis", object: "product" },

  { rank: 58, id: "product.case.dimension", title: "Dimension du colis", object: "product" },
  { rank: 59, id: "product.case.packaging.weight", title: "Poids du sur-emballage", object: "product" },
  { rank: 60, id: "product.case.packaging.material", title: "Matière du sur-emballage", object: "product" },

 

  { rank: 70, id: "product.pallet.type", title: "Type de palette", object: "product" },
  { rank: 71, id: "product.pallet.weight", title: "Poids de la palette", object: "product" },
  { rank: 72, id: "product.pallet.length", title: "Largeur de palette", object: "product" },
  { rank: 73, id: "product.pallet.weight_gross", title: "Poids Brut de palette", object: "product" },
  { rank: 74, id: "product.pallet.number_unit", title: "Nombre unités par palette", object: "product" },
  { rank: 75, id: "product.pallet.layers", title: "Nombre de couche de colis par palette", object: "product" },


  { rank: 90, id: "product.organoleptic.rind", title: "Caract. organoleptiques - Croûte", object: "organoleptic" },
  { rank: 90, id: "product.organoleptic.taste", title: "Caract. organoleptiques - Goût", object: "organoleptic" },
  { rank: 90, id: "product.organoleptic.dough", title: "Caract. organoleptiques - Pâte", object: "organoleptic" },
  { rank: 90, id: "product.organoleptic.texture", title: "Caract. organoleptiques - Texture", object: "organoleptic" },


  { rank: 100, id: "product.nutritional.cent.fat.total", title: "Total Matière Grasse pour 100g", object: "nutritional" },
  { rank: 101, id: "product.nutritional.cent.fat.saturated", title: "Matière Grasse Saturé 100g", object: "nutritional" },
  { rank: 102, id: "product.nutritional.cent.fat.trans", title: "Matière Grasse Trans 100g", object: "nutritional" },
  { rank: 103, id: "product.nutritional.cent.cholesterol", title: "Cholesterol pour 100g", object: "nutritional" },
  { rank: 104, id: "product.nutritional.cent.sodium.total", title: "Sodium pour 100g", object: "nutritional" },
  { rank: 105, id: "product.nutritional.cent.sodium.salt", title: "Sel pour 100g", object: "nutritional" },
  { rank: 106, id: "product.nutritional.cent.carbohydrate.total", title: "Glucides 100g", object: "nutritional" },
  { rank: 107, id: "product.nutritional.cent.fiber.total", title: "Fibres pour 100g", object: "nutritional" },
  { rank: 108, id: "product.nutritional.cent.carbohydrate.sugar", title: "Sucres pour 100g", object: "nutritional" },
  { rank: 109, id: "product.nutritional.cent.protein.total", title: "Protéines pour 100g", object: "nutritional" },
  { rank: 110, id: "product.nutritional.cent.vitaminD.total", title: "Vitamine D pour 100g", object: "nutritional" },
  { rank: 111, id: "product.nutritional.cent.calcium.total", title: "Calcium pour 100g", object: "nutritional" },
  { rank: 112, id: "product.nutritional.cent.iron.total", title: "Fer pour 100g", object: "nutritional" },
  { rank: 113, id: "product.nutritional.cent.potassium.total", title: "Potassium pour 100g", object: "nutritional" },
  { rank: 114, id: "product.nutritional.cent.energy.total", title: "Valeurs énergétique pour 100g", object: "item" },
  { rank: 115, id: "product.nutritional.cent.calories.total", title: "Calories pour 100g", object: "item" },
  { rank: 116, id: "product.nutritional.cent.calories.fat", title: "Calories venant de la MG pour 100g", object: "item" },


  { rank: 200, id: "product.nutritional.percent.fat.total", title: "Total Matière Grasse en % Apport Journalier", object: "nutritional" },
  { rank: 201, id: "product.nutritional.percent.fat.saturated", title: "Matière Grasse Saturé en % Apport Journalier", object: "nutritional" },
  { rank: 202, id: "product.nutritional.percent.fat.trans", title: "Matière Grasse Trans en % Apport Journalier", object: "nutritional" },
  { rank: 203, id: "product.nutritional.percent.cholesterol", title: "Cholesterol en % Apport Journalier", object: "nutritional" },
  { rank: 204, id: "product.nutritional.percent.sodium.total", title: "Sodium en % Apport Journalier", object: "nutritional" },
  { rank: 205, id: "product.nutritional.percent.sodium.salt", title: "Sel pour une part", object: "nutritional" },
  { rank: 206, id: "product.nutritional.percent.carbohydrate.total", title: "Glucides en % Apport Journalier", object: "nutritional" },
  { rank: 207, id: "product.nutritional.percent.fiber.total", title: "Fibres en % Apport Journalier", object: "nutritional" },
  { rank: 208, id: "product.nutritional.percent.carbohydrate.sugar", title: "Sucres en % Apport Journalier", object: "nutritional" },
  { rank: 209, id: "product.nutritional.percent.protein.total", title: "Protéines en % Apport Journalier", object: "nutritional" },
  { rank: 210, id: "product.nutritional.percent.vitaminD.total", title: "Vitamine D en % Apport Journalier", object: "nutritional" },
  { rank: 211, id: "product.nutritional.percent.calcium.total", title: "Calcium en % Apport Journalier", object: "nutritional" },
  { rank: 212, id: "product.nutritional.percent.iron.total", title: "Fer en % Apport Journalier", object: "nutritional" },
  { rank: 213, id: "product.nutritional.percent.potassium.total", title: "Potassium en % Apport Journalier", object: "nutritional" },
  { rank: 214, id: "product.nutritional.percent.energy.total", title: "Valeurs énergétique en % Apport Journalier", object: "item" },
  { rank: 215, id: "product.nutritional.percent.calories.total", title: "Calories en % Apport Journalier", object: "item" },
  { rank: 216, id: "product.nutritional.percent.calories.fat", title: "Calories venant de la MG en % Apport Journalier", object: "item" },


  { rank: 130, id: "product.nutritional.serving.size", title: "Poids pour une portion", object: "nutritional" },
  { rank: 131, id: "product.nutritional.serving.number", title: "Nombre de portion pour 1 Kg", object: "nutritional" },

  { rank: 300, id: "product.nutritional.serving.fat.total", title: "Total Matière Grasse pour une part", object: "nutritional" },
  { rank: 301, id: "product.nutritional.serving.fat.saturated", title: "Matière Grasse Saturée pour une part", object: "nutritional" },
  { rank: 302, id: "product.nutritional.serving.fat.trans", title: "Matière Grasse Trans pour une part", object: "nutritional" },
  { rank: 303, id: "product.nutritional.serving.cholesterol", title: "Cholesterol pour une part", object: "nutritional" },
  { rank: 304, id: "product.nutritional.serving.sodium.total", title: "Sodium pour une part", object: "nutritional" },
  { rank: 305, id: "product.nutritional.serving.sodium.salt", title: "Sel pour une part", object: "nutritional" },
  { rank: 306, id: "product.nutritional.serving.carbohydrate.total", title: "Glucides pour une part", object: "nutritional" },
  { rank: 307, id: "product.nutritional.serving.fiber.total", title: "Fibres pour une part", object: "nutritional" },
  { rank: 308, id: "product.nutritional.serving.carbohydrate.sugar", title: "Sucres pour une part", object: "nutritional" },
  { rank: 309, id: "product.nutritional.serving.protein.total", title: "Protéines pour une part", object: "nutritional" },
  { rank: 310, id: "product.nutritional.serving.vitaminD.total", title: "Vitamine D pour une part", object: "nutritional" },
  { rank: 311, id: "product.nutritional.serving.calcium.total", title: "Calcium pour une part", object: "nutritional" },
  { rank: 312, id: "product.nutritional.serving.iron.total", title: "Fer pour une part", object: "nutritional" },
  { rank: 313, id: "product.nutritional.serving.potassium.total", title: "Potassium pour une part", object: "nutritional" },
  { rank: 314, id: "product.nutritional.serving.energy.total", title: "Valeurs énergétique pour un part", object: "item" },
  { rank: 315, id: "product.nutritional.serving.calories.total", title: "Calories pour une part", object: "item" },
  { rank: 316, id: "product.nutritional.serving.calories.fat", title: "Calories venant de la MG pour une part", object: "item" },

  { rank: 400, id: "item.allergens_text", title: "Description des alergènes", object: "alergens" },

  { rank: 500, id: "item.ingredients_text", title: "Description des Ingrédients", object: "ingredients" }, //product.ingredients.description
  { rank: 500, id: "product.awards.description", title: "Récompenses & Médailles", object: "awards" },
];



const TYPE_PHOTOS = [
  { rank: 0, id: "-", title: "Pas dynamique", object: "-" },

  { rank: 10, id: "product.image_front_product", title: "Photo principale du produit", object: "product" },
  { rank: 11, id: "product.image_back_product", title: "Photo secondaire du produit", object: "product" },
  { rank: 20, id: "item.image_front_product", title: "Photo principale de l'article", object: "product" },
  { rank: 21, id: "item.image_back_product", title: "Photo secondaire de l'article", object: "product" },
  { rank: 22, id: "item.photo_1", title: "Photo Bibliothéque #1", object: "product" },
  { rank: 23, id: "item.photo_2", title: "Photo Bibliothéque #2", object: "product" },
  { rank: 24, id: "item.photo_3", title: "Photo Bibliothéque #3", object: "product" },
  { rank: 24, id: "item.photo_4", title: "Photo Bibliothéque #4", object: "product" },
  { rank: 50, id: "product.photo_award1", title: "Image du prix #1", object: "product" },
  { rank: 51, id: "product.photo_award2", title: "Image du prix #2", object: "product" },
  { rank: 52, id: "product.photo_award3", title: "Image du prix #3", object: "product" },
  { rank: 60, id: "brand.logo_white", title: "Logo de la marque", object: "brand" },
]



const TYPE_EAN = [
  { rank: 0, id: "-", title: "Pas dynamique", object: "-" },

  { rank: 10, id: "ean13", title: "Format EAN13", object: "product" },
  { rank: 11, id: "ean128", title: "Format EAN128", object: "product" }
]


const DPI = 72.0
const convertPixelToCm = function (value, dpi) {
  let nbPixelPerCm = (dpi / 2.54)
  console.log("dpi", dpi)
  console.log("nbPixelPerCm", nbPixelPerCm)
  console.log("parseFloat(value) ", parseFloat(value))
  return (parseFloat(value) / nbPixelPerCm).toFixed(2)
}





const VariableEANType = observer(({ store, element, elements }) => {


  const getCurentItem = (store) => {
    const selectedElement = store.selectedElements[0];

    if (selectedElement) {
      if (selectedElement.custom) {
        console.log("")
        console.log("Custom")
        console.log(selectedElement.custom)
        if (selectedElement.custom.type) {
          console.log(selectedElement.custom.type)
          for (let i = 0; i < TYPE_EAN.length; i++) {
            if (selectedElement.custom.type == TYPE_EAN[i].id) {
              console.log("found", TYPE_EAN[i].id)

              return TYPE_EAN[i];
            }

          }

        }
      }
    }
    return TYPE_EAN[0]
  }



  const filterVariable = (query, variable, _index, exactMatch) => {
    const normalizedTitle = variable.title.toLowerCase();
    const normalizedQuery = query.toLowerCase();

    if (exactMatch) {
      return normalizedTitle === normalizedQuery;
    } else {
      return `${variable.rank}. ${normalizedTitle} ${variable.title}`.indexOf(normalizedQuery) >= 0;
    }
  };


  const renderVariable = (variable, { handleClick, handleFocus, modifiers, query }) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }




    return (
      <MenuItem
        active={modifiers.active}
        disabled={modifiers.disabled}
        key={variable.rank}
        label={variable.title.toString()}
        onClick={handleClick}
        onFocus={handleFocus}

        roleStructure="listoption"
        text={`${variable.rank}. ${variable.title}`}
      />
    );
  };

  function onActiveItemChange(item) {
    console.log("onActiveItemChange - onActiveItemChange - onActiveItemChange")
    console.log(item.id)
    setSelectedVariable(item)
    let selectedElement = store.selectedElements[0];

    selectedElement.set(
      {
        custom: {
          source: item.id,
        },
      })


  }
  let cur = getCurentItem(store)
  console.log('VariableEANSelect selectedVariable =', cur)


  let [selectVariable, setSelectedVariable] = React.useState(cur);

  selectVariable = cur
  console.log('selectVariable =', selectVariable)

  return (
    <Select2
      style={{ width: '100%' }}
      items={TOP_100_FILMS}
      itemPredicate={filterVariable}
      onItemSelect={onActiveItemChange}
      itemRenderer={renderVariable}
      noResults={<MenuItem disabled={true} text="No results." roleStructure="listoption" />}
    // onItemSelect={setSelectedPhoto}
    >
      <Button style={{ width: '100%' }} text={selectVariable?.title} rightIcon="double-caret-vertical" placeholder="Select a variable" />
    </Select2>
  );
});










const VariableEANSelect = observer(({ store, element, elements }) => {


  const getCurentItem = (store) => {
    const selectedElement = store.selectedElements[0];

    if (selectedElement) {
      if (selectedElement.custom) {
        console.log("")
        console.log("Custom")
        console.log(selectedElement.custom)
        if (selectedElement.custom.source) {
          console.log(selectedElement.custom.source)
          for (let i = 0; i < TOP_100_FILMS.length; i++) {
            if (selectedElement.custom.source == TOP_100_FILMS[i].id) {
              console.log("found", TOP_100_FILMS[i].id)

              return TOP_100_FILMS[i];
            }

          }

        }
      }
    }
    return TOP_100_FILMS[0]
  }






  const filterVariable = (query, variable, _index, exactMatch) => {
    const normalizedTitle = variable.title.toLowerCase();
    const normalizedQuery = query.toLowerCase();

    if (exactMatch) {
      return normalizedTitle === normalizedQuery;
    } else {
      return `${variable.rank}. ${normalizedTitle} ${variable.title}`.indexOf(normalizedQuery) >= 0;
    }
  };


  const renderVariable = (variable, { handleClick, handleFocus, modifiers, query }) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }




    return (
      <MenuItem
        active={modifiers.active}
        disabled={modifiers.disabled}
        key={variable.rank}
        label={variable.title.toString()}
        onClick={handleClick}
        onFocus={handleFocus}

        roleStructure="listoption"
        text={`${variable.rank}. ${variable.title}`}
      />
    );
  };

  function onActiveItemChange(item) {
    console.log("onActiveItemChange - onActiveItemChange - onActiveItemChange")
    console.log(item.id)
    setSelectedVariable(item)
    let selectedElement = store.selectedElements[0];

    selectedElement.set(
      {
        custom: {
          source: item.id,
        },
      })


  }
  let cur = getCurentItem(store)
  console.log('VariableEANSelect selectedVariable =', cur)


  let [selectVariable, setSelectedVariable] = React.useState(cur);

  selectVariable = cur
  console.log('selectVariable =', selectVariable)

  return (
    <Select2
      style={{ width: '100%' }}
      items={TOP_100_FILMS}
      itemPredicate={filterVariable}
      onItemSelect={onActiveItemChange}
      itemRenderer={renderVariable}
      noResults={<MenuItem disabled={true} text="No results." roleStructure="listoption" />}
    // onItemSelect={setSelectedPhoto}
    >
      <Button style={{ width: '100%' }} text={selectVariable?.title} rightIcon="double-caret-vertical" placeholder="Select a variable" />
    </Select2>
  );
});







const PhotoSelect = observer(({ store, element, elements }) => {


  const getCurentItem = (store) => {
    const selectedElement = store.selectedElements[0];

    if (selectedElement) {
      if (selectedElement.custom) {
        console.log("")
        console.log("Custom")
        console.log(selectedElement.custom)
        if (selectedElement.custom.source) {
          console.log(selectedElement.custom.source)
          for (let i = 0; i < TYPE_PHOTOS.length; i++) {
            if (selectedElement.custom.source == TYPE_PHOTOS[i].id) {
              console.log("found", TYPE_PHOTOS[i].id)

              return TYPE_PHOTOS[i];
            }

          }

        }
      }
    }
    return TYPE_PHOTOS[0]
  }




  const filterPhoto = (query, photo, _index, exactMatch) => {
    const normalizedTitle = photo.title.toLowerCase();
    const normalizedQuery = query.toLowerCase();

    if (exactMatch) {
      return normalizedTitle === normalizedQuery;
    } else {
      return `${photo.rank}. ${normalizedTitle} ${photo.title}`.indexOf(normalizedQuery) >= 0;
    }
  };


  const renderPhoto = (photo, { handleClick, handleFocus, modifiers, query }) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }




    return (
      <MenuItem
        active={modifiers.active}
        disabled={modifiers.disabled}
        key={photo.rank}
        label={photo.title.toString()}
        onClick={handleClick}
        onFocus={handleFocus}

        roleStructure="listoption"
        text={`${photo.rank}. ${photo.title}`}
      />
    );
  };

  function onActiveItemChange(item) {
    console.log("onActiveItemChange - onActiveItemChange - onActiveItemChange")
    console.log(item.id)
    setSelectedPhoto(item)
    let selectedElement = store.selectedElements[0];

    selectedElement.set(
      {
        custom: {
          source: item.id,
        },
      })


  }
  let cur = getCurentItem(store)
  console.log('PhotoSelect selectedPhoto =', cur)


  let [selectPhoto, setSelectedPhoto] = React.useState(cur);

  selectPhoto = cur
  console.log('selectPhoto =', selectPhoto)

  return (
    <Select2
      style={{ width: '100%' }}
      items={TYPE_PHOTOS}
      itemPredicate={filterPhoto}
      onItemSelect={onActiveItemChange}
      itemRenderer={renderPhoto}
      noResults={<MenuItem disabled={true} text="No results." roleStructure="listoption" />}
    // onItemSelect={setSelectedPhoto}
    >
      <Button style={{ width: '100%' }} text={selectPhoto?.title} rightIcon="double-caret-vertical" placeholder="Select a photo" />
    </Select2>
  );
});



const PositionTab = observer(({ store }) => {
  // load data
  const text = store.selectedElements[0]?.text;

  const custom = store.selectedElements[0]?.custom?.source;
  let format = store.selectedElements[0]?.custom?.format;

  if (format == undefined) format = ""
  const editorRef = useRef(null);
  return (
    <div>
      <div style={{ width: '100%', display: 'flex', 'paddingLeft': '10px', flexDirection: 'row' }}>

        <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>

          <div style={{ marginTop: '20px', marginBottom: '7px' }}>X en cm</div>
          <NumericInput
            style={{ width: '100px' }}
            allowNumericCharactersOnly={false}
            value={

              pxToUnitRounded({
                px: store.selectedElements[0]?.x,
                unit: 'cm',
                precious: 2,
                dpi: 72,
              })


            }

            rightElement={

              <Popover
                position="bottom"
                content={
                  <Menu>
                    <MenuItem text={"cm"} />
                  </Menu>
                }
              >
                <Button minimal={true}   >cm</Button>
              </Popover>

            }
            stepSize={0.10}
            minorStepSize={0.01}
            majorStepSize={1.00}
            onValueChange={(value) => {
              console.log("x=", value)
              store.selectedElements[0]?.set({
                x:
                  unitToPx({
                    unit: 'cm',
                    dpi: 72,
                    unitVal: value
                  })




              });
              // store.activePage.set({ bleed });
            }}
          />
        </div>

        <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <div style={{ marginTop: '20px', marginBottom: '7px' }}>Y en cm</div>
          <NumericInput
            style={{ width: '100px' }}
            allowNumericCharactersOnly={false}
            value={
              pxToUnitRounded({
                px: store.selectedElements[0]?.y,
                unit: 'cm',
                precious: 2,
                dpi: 72,
              })

            }
            stepSize={0.10}
            minorStepSize={0.01}
            majorStepSize={1.00}
            rightElement={

              <Popover
                position="bottom"
                content={
                  <Menu>
                    <MenuItem text={"cm"} />
                  </Menu>
                }
              >
                <Button minimal={true}   >cm</Button>
              </Popover>

            }
            onValueChange={(value) => {
              console.log("y=", value)
              store.selectedElements[0]?.set({
                y: unitToPx({
                  unit: 'cm',
                  dpi: 72,
                  unitVal: value
                })
              });
              // store.activePage.set({ bleed });
            }}
          />

        </div>



      </div>

      <div style={{ height: '100%', display: 'flex', 'paddingLeft': '10px', flexDirection: 'row' }}>
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <div style={{ marginTop: '20px', marginBottom: '7px' }}>Largeur</div>
          <NumericInput
            style={{ width: '100px' }}
            allowNumericCharactersOnly={true}
            value={pxToUnitRounded({
              px: store.selectedElements[0]?.width,
              unit: 'cm',
              precious: 2,
              dpi: 72,
            })

            }
            round={true}
            stepSize={0.10}
            minorStepSize={0.01}
            majorStepSize={1.00}
            rightElement={

              <Popover
                position="bottom"
                content={
                  <Menu>
                    <MenuItem text={"cm"} />
                  </Menu>
                }
              >
                <Button minimal={true}   >cm</Button>
              </Popover>

            }
            onValueChange={(value) => {
              console.log("width=", value)

              store.selectedElements[0]?.set({
                width: unitToPx({
                  unit: 'cm',
                  dpi: 72,
                  unitVal: value
                })
              });

            }}
          />
        </div>
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <div style={{ marginTop: '20px', marginBottom: '7px' }}>Hauteur</div>
          <NumericInput
            style={{ width: '100px' }}
            stepSize={0.10}
            minorStepSize={0.01}
            majorStepSize={1.00}
            value={pxToUnitRounded({
              px: store.selectedElements[0]?.height,
              unit: 'cm',
              precious: 2,
              dpi: 72,
            })}
            disabled={(store.selectedElements[0]?.height == 'text')}
            rightElement={

              <Popover
                position="bottom"
                content={
                  <Menu>
                    <MenuItem text={"cm"} />
                  </Menu>
                }
              >
                <Button minimal={true}   >cm</Button>
              </Popover>

            }
            onValueChange={(value) => {
              console.log("height = ", value)
              store.selectedElements[0]?.set({
                height: unitToPx({
                  unit: 'cm',
                  dpi: 72,
                  unitVal: value
                })
              });
            }}
          />
        </div>
      </div>

      <div style={{ height: '100%', 'paddingLeft': '10px', display: 'flex', flexDirection: 'row' }}>
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <div style={{ marginTop: '20px', marginBottom: '7px' }} >Rotation s</div>
          <NumericInput
            style={{ width: '100px' }}
            value={store.selectedElements[0]?.rotation}
            rightElement={
              <Popover
                position="bottom"
                content={
                  <Menu>
                    <MenuItem text={"°"} />
                  </Menu>
                }
              >
                <Button minimal={true}   >°</Button>
              </Popover>

            }

            onValueChange={(value) => {
              console.log(value)
              store.selectedElements[0]?.set({ rotation: value });
              // store.activePage.set({ bleed });
            }}
          />
        </div>
      </div>
    </div>

  )
})

const TextTab = observer(({ store }) => {
  // load data
  const text = store.selectedElements[0]?.text;

  const custom = store.selectedElements[0]?.custom?.source;
  let format = store.selectedElements[0]?.custom?.format;

  if (format == undefined) format = ""
  const editorRef = useRef(null);

  return (
    <div>

      <div style={{ marginTop: '20px', marginBottom: '7px' }}>Texte par défaut</div>


      <Editor
        style={{ 'border-radius': '0px', 'margin-bottom': '20px' }}
        onInit={(evt, editor) => editorRef.current = editor}
        initialValue={text}
        apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}

        onEditorChange={(value) => {
//A revoir
      //    store.selectedElements[0]?.set({ text: value });
        }}

        init={{
          height: 150,
          menubar: false,
          plugins: [ 
          ],
          toolbar: 'undo redo | formatselect | ' +
            'bold italic backcolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:10px }'
        }}
      />


    </div>
  )
})



const ImageTab = observer(({ store }) => {
  // load data
  const text = store.selectedElements[0]?.text;

  const custom = store.selectedElements[0]?.custom?.source;
  let format = store.selectedElements[0]?.custom?.format;

  if (format == undefined) format = ""

  return (
    <div>

      <div style={{ marginTop: '20px', marginBottom: '7px' }}>Image par défaut</div>



    </div>
  )
})


const EANTab = observer(({ store }) => {
  // load data
  const text = store.selectedElements[0]?.text;

  const custom = store.selectedElements[0]?.custom?.source;
  let format = store.selectedElements[0]?.custom?.format;

  if (format == undefined) format = ""

  return (
    <div>

      <div style={{ marginTop: '20px', marginBottom: '7px' }}>Format Code Barre</div>



    </div>
  )
})


const QRTab = observer(({ store }) => {
  // load data
  const text = store.selectedElements[0]?.text;

  const custom = store.selectedElements[0]?.custom?.source;
  let format = store.selectedElements[0]?.custom?.format;

  if (format == undefined) format = ""

  return (
    <div>

      <div style={{ marginTop: '20px', marginBottom: '7px' }}>Format QR Code</div>



    </div>
  )
})


const VariableTab = observer(({ store }) => {
  // load data

  const text = store.selectedElements[0]?.text;

  const custom = store.selectedElements[0]?.custom?.source;
  let format = store.selectedElements[0]?.custom?.format;

  if (format == undefined) format = ""
  const editorRef = useRef(null);

  const showHideDemo1 = ((custom == undefined) || (custom != 'custom')) ;

//  visibility={((custom == undefined) || (custom != 'custom'))  ? "hidden": "visible"}

  return (

    <div>

      <div style={{ display: 'none', marginTop: '20px', marginBottom: '7px' }}>Paramétre dynamique</div>



      <InputGroup
        value={custom}
        style={{ display: 'none' }}
        disabled={(custom == undefined)}
        onChange={(e) => {
          store.selectedElements[0]?.set({ text: e.target.value });
        }}
      />

      <div style={{ marginTop: '20px', marginBottom: '7px' }}>Variable dynamique</div>

      <VariableEANSelect store={store} />
   


      {(!showHideDemo1 &&
      <div>
   <div style={{ marginTop: '20px', marginBottom: '7px' }}>format personnalisé</div>
      <Editor
        style={{ 'border-radius': '0px',  display: ((custom == undefined) || (custom != 'custom'))  ? "none": "none" }}
        apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
        onInit={(evt, editor) => editorRef.current = editor}
        initialValue={format}
        onChange={(e) => {
          console.log({ custom: { format: e.target.value } });
          store.selectedElements[0]?.set({ custom: { format: e.target.getContent(), source: 'custom' } });

        }}
 

        onEditorChange={(value) => {
        //  store.selectedElements[0]?.set({ custom: { format: value, source: 'custom' } });
        }}

      
        disabled={((custom == undefined) || (custom != 'custom'))}
        init={{
          height: 200,
          menubar: false,
          statusbar: false,
          plugins: [

          ],
          toolbar: 'undo redo | formatselect | ' +
            'bold italic backcolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif;  border-radius: 3px;font-size:12px }'
        }}
      />
        </div>
        )}

    </div>


  )
})




const VariableImageTab = observer(({ store }) => {
  // load data

  const text = store.selectedElements[0]?.text;

  const custom = store.selectedElements[0]?.custom?.source;
  let format = store.selectedElements[0]?.custom?.format;

  if (format == undefined) format = ""
  const editorRef = useRef(null);



  return (

    <div>
      <div style={{ marginTop: '20px', marginBottom: '7px' }}>Paramétre dynamique</div>


      <div style={{ marginTop: '20px', marginBottom: '7px' }}>Paramétre dynamique</div>
      <PhotoSelect store={store} />



    </div>


  )
})


const VariableEANTab = observer(({ store }) => {
  // load data

  const text = store.selectedElements[0]?.text;

  const custom = store.selectedElements[0]?.custom?.source;
  let format = store.selectedElements[0]?.custom?.format;

  if (format == undefined) format = ""
  const editorRef = useRef(null);

  return (

    <div>
      <div style={{ marginTop: '20px', marginBottom: '7px' }}>Paramétre dynamique pour le code EAN</div>
      <VariableEANSelect store={store} />
    </div>

  )
})


const VariableQRTab = observer(({ store }) => {
  // load data

  const text = store.selectedElements[0]?.text;

  const custom = store.selectedElements[0]?.custom?.source;
  let format = store.selectedElements[0]?.custom?.format;

  if (format == undefined) format = ""
  const editorRef = useRef(null);
  return (
    <div>
     <div style={{ marginTop: '20px', marginBottom: '7px' }}>Variable pour Code EAN</div>
      <VariableEANSelect store={store} />
     </div>
   )
})


const EmptyTab = observer(({ store }) => {
  // load data




  return (

    <div>

      <div style={{ marginTop: '20px', marginBottom: '7px' }}>Pas de variable dynamique pour ce type d'objet</div>




    </div>


  )
})
 


const CustomSection = {
  name: 'custom-text',
  // we don't need "Tab" property, because it will be hidden from the list
  visibleInList: false,
  // we need observer to update component automatically on any store changes
  Panel: observer(({ store }) => {
    const [selectedTabId, setSelectedTabId] = React.useState('position');
    //
    //99a0fbb2-f577-457c-8438-cdff9d025a04
    const text = store.selectedElements[0]?.text;

    const custom = store.selectedElements[0]?.custom?.source;
    let format = store.selectedElements[0]?.custom?.format;

    if (format == undefined) format = ""
    const editorRef = useRef(null);
    const log = () => {
      if (editorRef.current) {
        console.log(editorRef.current.getContent());
      }
    };
    console.log(store.selectedElements[0]?.width)
    return (
      <div>
        <div style={{ marginTop: '20px', marginBottom: '7px' }}>Paramétres</div>


        <Tabs defaultSelectedTabId="position"
          onChange={(tabId) => {
            setSelectedTabId(tabId);
          }}>
          <Tab id="position" title="Positions" />
          <Tab id="text" title="Exemple" />
          <Tab id="variable" title="Données Dynamiques" />
        </Tabs>

        <div
          style={{
            height: 'calc(100% - 20px)',
            display: 'flex',
            flexDirection: 'column',
            paddingTop: '20px',
          }}
        >
          {selectedTabId === 'position' && <PositionTab store={store} />}
          {selectedTabId === 'text' && <TextTab store={store} />}
          {selectedTabId === 'variable' && <VariableTab store={store} />}
        </div>





      </div>
    );
  }),
};

const CustomImageSection = {
  name: 'custom-image',
  // we don't need "Tab" property, because it will be hidden from the list
  visibleInList: false,
  // we need observer to update component automatically on any store changes
  Panel: observer(({ store }) => {
    const [selectedTabId, setSelectedTabId] = React.useState('position');
    //
    //99a0fbb2-f577-457c-8438-cdff9d025a04
    const text = store.selectedElements[0]?.text;

    const custom = store.selectedElements[0]?.custom?.source;
    let format = store.selectedElements[0]?.custom?.format;

    if (format == undefined) format = ""
    const editorRef = useRef(null);
    const log = () => {
      if (editorRef.current) {
        console.log(editorRef.current.getContent());
      }
    };
    console.log(store.selectedElements[0]?.width)
    return (
      <div>
        <div style={{ marginTop: '20px', marginBottom: '7px' }}>Paramétres</div>


        <Tabs defaultSelectedTabId="position"
          onChange={(tabId) => {
            setSelectedTabId(tabId);
          }}>
          <Tab id="position" title="Positions" />
          <Tab id="text" title="Exemple" />
          <Tab id="variable" title="Données Dynamiques" />
        </Tabs>

        <div
          style={{
            height: 'calc(100% - 20px)',
            display: 'flex',
            flexDirection: 'column',
            paddingTop: '20px',
          }}
        >
          {selectedTabId === 'position' && <PositionTab store={store} />}
          {selectedTabId === 'image' && <ImageTab store={store} />}
          {selectedTabId === 'variable' && <VariableImageTab store={store} />}
        </div>
      </div>
    );
  }),
};


const CustomSVGSection = {
  name: 'custom-svg',
  // we don't need "Tab" property, because it will be hidden from the list
  visibleInList: false,
  // we need observer to update component automatically on any store changes
  Panel: observer(({ store }) => {

    const [selectedTabId, setSelectedTabId] = React.useState('position');
    //99a0fbb2-f577-457c-8438-cdff9d025a04
    const name = store.selectedElements[0]?.name;

    const custom = store.selectedElements[0]?.custom?.source;
    let format = store.selectedElements[0]?.custom?.format;

    if (format == undefined) format = ""
    const editorRef = useRef(null);
    const log = () => {
      if (editorRef.current) {
        console.log(editorRef.current.getContent());
      }
    };
    console.log(store.selectedElements[0]?.width)

    const isQR = (name == 'qr')
    const isEAN = (name == 'barcode')
    const isOther = ((name != 'qr') && (name != 'barcode'))

    return (
      <div>
        <div style={{ marginTop: '20px', marginBottom: '7px' }}>Paramétres</div>


        <Tabs defaultSelectedTabId="position"
          onChange={(tabId) => {
            setSelectedTabId(tabId);
          }}>
          <Tab id="position" title="Positions" />
          <Tab id="svg" title="Format" />
          <Tab id="variable" title="Données Dynamiques" />
        </Tabs>

        <div
          style={{
            height: 'calc(100% - 20px)',
            display: 'flex',
            flexDirection: 'column',
            paddingTop: '20px',
          }}
        >
          {selectedTabId === 'position' && <PositionTab store={store} />}

          {selectedTabId === 'svg' && isEAN && <EANTab store={store} />}
          {selectedTabId === 'svg' && isQR && <QRTab store={store} />}
          {selectedTabId === 'svg' && isOther && <EmptyTab store={store} />}


          {(selectedTabId === 'variable' && isEAN) && <VariableEANTab store={store} />}
          {(selectedTabId === 'variable' && isQR) && <VariableQRTab store={store} />}
          {(selectedTabId === 'variable') && isOther && <EmptyTab store={store} />}
        </div>
      </div>
    );
  }),
};


// DEFAULT_SECTIONS.splice(3, 0, IllustrationsSection);
// replace elements section with just shapes
//DEFAULT_SECTIONS.splice(3, 1, ShapesSection);
// DEFAULT_SECTIONS.splice(10, 1, ShapesSection);
//DEFAULT_SECTIONS.splice(1, 0, GridSection);

// DEFAULT_SECTIONS.splice(1, 0, VariableSection);
// add icons
//Remove Resize
DEFAULT_SECTIONS.splice(7, 1, IconsSection);
// add two more sections
DEFAULT_SECTIONS.push(QuotesSection);
DEFAULT_SECTIONS.push(QrSection);
DEFAULT_SECTIONS.push(BarcodeSection);
DEFAULT_SECTIONS.push(CustomSection);
DEFAULT_SECTIONS.push(CustomSVGSection);
DEFAULT_SECTIONS.push(CustomImageSection);
//DEFAULT_SECTIONS.push(BarcodeSection,  );
//DEFAULT_SECTIONS.unshift(MyDesignsSection);

// DEFAULT_SECTIONS.push(StableDiffusionSection);
//DEFAULT_SECTIONS.push(MyDesignsSection);

//window.store.addPage({bleed: 20 });
//window.store.toggleBleed();

const MyTopbar = observer(({ store }) => {
  let x = 0;
  let w = 0;
  let h = 0;
  let y = 0;

  let element = store.selectedElements[0];
  if (element != null) {
    w = convertPixelToCm(element.width, store.dpi)
    h = convertPixelToCm(element.height, store.dpi)
    x = convertPixelToCm(element.x, store.dpi)
    y = convertPixelToCm(element.y, store.dpi)
  }

  return (
    <Navbar>
      <Navbar.Group align="left">
        <div style={{ marginRight: '20px' }}>Bleed size (px):</div>
        <NumericInput
          value={store.activePage.bleed}
          onValueChange={(bleed) => {
            store.activePage.set({ bleed });
          }}
        />
        <div style={{ marginRight: '20px' }}>x (cm):</div>
        <NumericInput
          style={{ width: '50px' }}
          value={x}
          onValueChange={(x) => {
            //store.activePage.set({ bleed });
          }}
        />
        <Button
          onClick={() => {
            store.toggleBleed();
          }}
          active={store.bleedVisible}
          style={{ marginLeft: '20px' }}
        >
          Toggle bleed on Workspace
        </Button>
        <div>
          ({x})cm x ({y})cm -  ({w})cm x ({h})cm
        </div>
      </Navbar.Group>
      <Navbar.Group align="right">
        <Button
          onClick={() => {
            store.saveAsImage({ includeBleed: true });
          }}
          style={{ marginLeft: '20px' }}
        >
          Export
        </Button>
      </Navbar.Group>
    </Navbar>
  );
});




// add first bar code
const val = '10011';
//Genere un Barcode auto
getBarcode(val).then(({ src, width, height }) => {
  /* window.store.activePage.addElement({
     type: 'svg',
     name: 'barcode',
     x: window.store.width / 2 - 150,
     y: window.store.height / 2 - 100,
     width: 300,
     height: 200,
     src,
     width,
     height,
     custom: {
       value: val,
     },
   });*/
});








const useHeight = () => {
  const [height, setHeight] = React.useState(window.innerHeight);
  React.useEffect(() => {
    window.addEventListener('resize', () => {
      setHeight(window.innerHeight);
    });
  }, []);
  return height;
};


const App = observer(({ store }) => {
  const project = useProject();
  const height = useHeight();

  // store.activePage.set({bleed: 40 });
  window.store.toggleBleed();

  const { isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0();




  const get_query = () => {
    var url = document.location.href;
    var qs = url.substring(url.indexOf('?') + 1).split('&');
    for (var i = 0, result = {}; i < qs.length; i++) {
      qs[i] = qs[i].split('=');
      result[qs[i][0]] = decodeURIComponent(qs[i][1]);
    }
    return result;
  }
  const load = () => {
    let url = new URL(window.location.href);
    let query = get_query();
    //alert(JSON.stringify(get_query()))
    // url example https://studio.polotno.com/design/5f9f1b0b
    //const reg = new RegExp('design/([a-zA-Z0-9_-]+)').exec(url.pathname);
    // const designId = (reg && reg[1]) || '64c63b0eae9f4714d49d6015';
    // alert(designId)
    // alert(url.pathname)
    const designId = query.id || null;

    if (designId == null) return;
 
    project.loadById(designId);



  };

  React.useEffect(() => {
    if (isLoading) {
      return;
    }
    if (isAuthenticated) {
      getAccessTokenSilently()
        .then((token) => {
          project.authToken = token;
          load();
        })
        .catch((err) => {
          project.authToken = null;
          load();
          console.log(err);
        });
    } else {
      project.authToken = null;
      load();
    }
  }, [isAuthenticated, project, getAccessTokenSilently, isLoading]);

  const renderFilm = (film, { handleClick, handleFocus, modifiers, query }) => {
    console.log("renderFilm")
    if (!modifiers.matchesPredicate) {
      return null;
    }
    return (
      <MenuItem
        active={modifiers.active}
        disabled={modifiers.disabled}
        key={film.rank}
        label={film.object.toString()}
        onClick={handleClick}
        onFocus={handleFocus}
        roleStructure="listoption"
        text={`${film.rank}. ${film.title}`}
      />
    );
  };

  const renderGroupedItemList = (listProps) => {
    const initialContent = getInitialContent();
    const noResults = <MenuItem disabled={true} text="No results." roleStructure="listoption" />;

    // omit noResults if createNewItemFromQuery and createNewItemRenderer are both supplied, and query is not empty
    const createItemView = listProps.renderCreateItem();
    const maybeNoResults = createItemView != null ? null : noResults;

    const menuContent = renderGroupedMenuContent(listProps, maybeNoResults, initialContent);
    if (menuContent == null && createItemView == null) {
      return null;
    }

    return (
      <Menu role="listbox" {...listProps.menuProps} ulRef={listProps.itemsParentRef}>
        {createItemView}
        {menuContent}
        {createItemView}
      </Menu>
    );
  };



  const renderGroupedMenuContent = (
    listProps,
    noResults,
    initialContent,
  ) => {
    if (listProps.query.length === 0 && initialContent !== undefined) {
      return initialContent;
    }

    const groupedItems = getGroupedItems(listProps.filteredItems);

    const menuContent = groupedItems.map(groupedItem => (
      <React.Fragment key={groupedItem.key}>
        <MenuDivider title={groupedItem.group} />
        {groupedItem.items.map((item, index) => listProps.renderItem(item, groupedItem.index + index))}
      </React.Fragment>
    ));

    return groupedItems.length > 0 ? menuContent : noResults;
  };

  const getInitialContent = () => {
    return (
      <MenuItem disabled={true} text={`${TOP_100_FILMS.length} items loaded *.`} roleStructure="listoption" />
    );
  };



  const getGroup = (item) => {
    const firstLetter = item.title[0].toUpperCase();
    return /[0-9]/.test(firstLetter) ? "0-9" : firstLetter;
  }

  const getGroupedItems = (filteredItems) => {
    return filteredItems.reduce(
      (acc, item, index) => {
        const group = getGroup(item);

        const lastGroup = acc.at(-1);
        if (lastGroup && lastGroup.group === group) {
          lastGroup.items.push(item);
        } else {
          acc.push({ group, index, items: [item], key: index });
        }

        return acc;
      },
      [],
    );
  };


  const groupedItemListPredicate = (query, items) => {
    return items
      .filter((item, index) => filterFilm(query, item, index))
      .sort((a, b) => getGroup(a).localeCompare(getGroup(b)));
  };







  const filterFilm = (query, film, _index, exactMatch) => {
    const normalizedTitle = film.title.toLowerCase();
    const normalizedQuery = query.toLowerCase();

    if (exactMatch) {
      return normalizedTitle === normalizedQuery;
    } else {
      return `${film.rank}. ${normalizedTitle} ${film.object}`.indexOf(normalizedQuery) >= 0;
    }
  };

  const getCurentItem = (store) => {
    const selectedElement = store.selectedElements[0];

    if (selectedElement) {
      if (selectedElement.custom) {
        console.log("")
        console.log("Custom")
        console.log(selectedElement.custom)
        if (selectedElement.custom.source) {
          console.log(selectedElement.custom.source)
          for (let i = 0; i < TOP_100_FILMS.length; i++) {
            if (selectedElement.custom.source == TOP_100_FILMS[i].id) {
              console.log("found", TOP_100_FILMS[i].id)

              return TOP_100_FILMS[i];
            }

          }

        }
      }
    }
    return TOP_100_FILMS[0]
  }
  const MyColorPicker = observer(({ store, element, elements }) => {
    // store - main polotno store object
    // elements - array of selected elements. The same as store.selectedElements
    // element - first selected element. The same as store.selectedElements[0]
    return (
      <div>
        <input
          type="color"
          value={element.fill}
          onChange={(e) => {
            element.set({
              fill: e.target.value,
            });
          }}
        />
      </div>
    );
  });

  React.useEffect(() => {
    return autorun(() => {

      console.log("autorun", store.selectedElements[0]?.type)

      if (store.selectedElements[0]?.type === 'text') {
        store.openSidePanel('custom-text');
      } else
        if (store.selectedElements[0]?.type === 'image') {
          store.openSidePanel('custom-image');
        } else
          if (store.selectedElements[0]?.type === 'svg') {
            store.openSidePanel('custom-svg');
          }

          else {
            store.openSidePanel('text');
          }
    });
  }, []);


  const TextAlertButton = observer(({ store, element, elements }) => {

    let cur = getCurentItem(store)
    console.log('TextAlertButton selectedFilm =', cur)

    let [selectedFilm, setSelectedFilm2] = React.useState(cur);
    selectedFilm = cur
    console.log('selectedFilm =', selectedFilm)
    //console.log(setSelectedFilm2)

    //if (selectedFilm == undefined)
    //selectedFilm = TOP_100_FILMS[1]
    //   const selectedElements = store.selectedElements[0];
    // logs type of element
    // console.log(selectedElements.type);
    // logs id of element
    // console.log(selectedElements.id);



    function getActiveItem(item) {
      console.log("getActiveItem")
      //  console.log(TOP_100_FILMS[1])
      //  setSelectedFilm2(item)

    }
    function initialContent(item) {
      console.log("initialContent")
      console.log(TOP_100_FILMS[1])
      // setSelectedFilm2(TOP_100_FILMS[1])

    }
    function fill(item) {
      console.log("fill")
      console.log(TOP_100_FILMS[1])
      setSelectedFilm2(TOP_100_FILMS[1])

    }

    function onActiveItemChange(item) {
      console.log("onActiveItemChange - onActiveItemChange - onActiveItemChange")
      console.log(item.id)
      setSelectedFilm2(item)
      let selectedElement = store.selectedElements[0];
      //console.log(selectedElement)
      selectedElement.set(
        {
          custom: {
            source: item.id,
          },
        })
      // if(selectedElement.custom == undefined) selectedElement.custom = { }
      // selectedElement.custom.source = item.id
      // store  .selectedElements[0].custom.source = item.id
    }

    return (
      <>
        <Divider />

        <div style={{ 'display': 'flex', 'justifyContent': 'end', 'alignItems': 'center', 'marginLeft': '25px', 'marginRight': '10px' }}>
          Type :
        </div>
        <Select2
          // fill={fill}
          createNewItemPosition={"first"}
          items={TOP_100_FILMS}
          initialContent={initialContent}
          itemPredicate={filterFilm}
          itemRenderer={renderFilm}
          itemListPredicate={groupedItemListPredicate}
          itemListRenderer={renderGroupedItemList}
          activeItem={getActiveItem}

          onActiveItemChange={onActiveItemChange}
          noResults={<MenuItem disabled={true} text="No results." roleStructure="listoption" />}
          onItemSelect={setSelectedFilm2}
        >
          <Button text={selectedFilm?.title} rightIcon="double-caret-vertical" placeholder="Select a film" />
        </Select2 >





        <Button
          onClick={() => {
            alert('Hello!');
          }}
        >

        </Button> </>
    );
  });

  const handleDrop = (ev) => {
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();

    // skip the case if we dropped DOM element from side panel
    // in that case Safari will have more data in "items"
    if (ev.dataTransfer.files.length !== ev.dataTransfer.items.length) {
      return;
    }
    // Use DataTransfer interface to access the file(s)
    for (let i = 0; i < ev.dataTransfer.files.length; i++) {
      loadFile(ev.dataTransfer.files[i], store);
    }
  };
  //<MyTopbar store={store} />
  load()
  return (
    <div
      style={{
        width: '100vw',
        height: height + 'px',
        display: 'flex',
        flexDirection: 'column',
      }}
      onDrop={handleDrop}
    >

      <Topbar store={store} />

      <div style={{ height: 'calc(100% - 50px)' }}>
        <PolotnoContainer className="polotno-app-container">
          <SidePanelWrap>
            <SidePanel store={store} sections={DEFAULT_SECTIONS} defaultSection="text" />
          </SidePanelWrap>
          <WorkspaceWrap>
            
            <Toolbar
              store={store}
              components={{
                //   ImageRemoveBackground,
                ShowProperties,
                //  TextFill: MyColorPicker,

                TextAlertButton,

              }}
            />
            <Workspace store={store} />
            <ZoomButtons store={store} />
          </WorkspaceWrap>
        </PolotnoContainer>
      </div>

    </div>
  );
});

export default App;
